

<template>
  <!--<v-footer-->
    <!--id="dashboard-core-footer"-->
  <!--&gt;-->
    <!--<v-container>-->
      <!--<v-row-->
        <!--align="center"-->
        <!--no-gutters-->
      <!--&gt;-->
        <!--<v-col-->
          <!--v-for="(link, i) in links"-->
          <!--:key="i"-->
          <!--class="text-center mb-sm-0 mb-5"-->
          <!--cols="auto"-->
        <!--&gt;-->
          <!--<a-->
            <!--:href="link.href"-->
            <!--class="mr-0 grey&#45;&#45;text text&#45;&#45;darken-3"-->
            <!--rel="noopener"-->
            <!--target="_blank"-->
            <!--v-text="link.text"-->
          <!--/>-->
        <!--</v-col>-->

        <!--<v-spacer class="hidden-sm-and-down" />-->

        <!--<v-col-->
          <!--cols="12"-->
          <!--md="auto"-->
        <!--&gt;-->
          <!--<div class="body-1 font-weight-light pt-6 pt-md-0 text-center">-->
            <!--&copy; {{year}}, Corsair M360, All rights reserved.-->
          <!--</div>-->
        <!--</v-col>-->
      <!--</v-row>-->
    <!--</v-container>-->
  <!--</v-footer>-->
  <div></div>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({
      links: [
        {
          href: '#',
          text: 'm360api.io',
        },
        {
          href: '#',
          text: 'About',
        },
        {
          href: '#',
          text: 'Help',
        },
        {
          href: '#',
          text: 'License',
        },
      ],
      year: null
    }),

    created (){
      this.year = new Date().getFullYear();
    }
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
